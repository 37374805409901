import { graphql } from 'gatsby';
import { Location } from 'history';
import React from 'react';
import HirerAgreementBaseComponent, {
  HirerAgreementData,
} from 'components/FormPage/hirerAgreement';
import { DriverType } from 'types/forms';

type HirerAgreementProps = {
  data: {
    csAddDriverHirerOrNomineeAgreementTemplate: HirerAgreementData;
  };
  location: Location;
};

const HirerAgreementPage: React.FC<HirerAgreementProps> = ({ data, location }) => (
  <HirerAgreementBaseComponent
    data={data.csAddDriverHirerOrNomineeAgreementTemplate}
    location={location}
    driverType={DriverType.PERMANENT}
    formPageName="permanentDriverHirerAgreement"
  />
);

export default HirerAgreementPage;

export const query = graphql`
  query {
    csAddDriverHirerOrNomineeAgreementTemplate(driver_type: { eq: "Permanent" }) {
      meta_title
      heading
      subheading
      agreement
    }
  }
`;
